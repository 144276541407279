<template>
  <div class="border-4" style="height: 100%">
    <div class="grid grid-cols-4 grid-flow-row">
      <div>sum buy volume</div>
      <div>sum sell volume</div>
      <div>rate buy volume</div>
      <div>rate sell volume</div>
      <div>{{ $store.state.sum_buy_volume[0] }}</div>
      <div>{{ $store.state.sum_sell_volume[0] }}</div>
      <div>{{ $store.state.rate_buy_volume[0] }}</div>
      <div>{{ $store.state.rate_sell_volume[0] }}</div>
      <div>{{ $store.state.sum_buy_volume[1] }}</div>
      <div>{{ $store.state.sum_sell_volume[1] }}</div>
      <div>{{ $store.state.rate_buy_volume[1] }}</div>
      <div>{{ $store.state.rate_sell_volume[1] }}</div>
      <div>{{ $store.state.sum_buy_volume[2] }}</div>
      <div>{{ $store.state.sum_sell_volume[2] }}</div>
      <div>{{ $store.state.rate_buy_volume[2] }}</div>
      <div>{{ $store.state.rate_sell_volume[2] }}</div>
      <div>{{ $store.state.sum_buy_volume[3] }}</div>
      <div>{{ $store.state.sum_sell_volume[3] }}</div>
      <div>{{ $store.state.rate_buy_volume[3] }}</div>
      <div>{{ $store.state.rate_sell_volume[3] }}</div>
      <div>{{ $store.state.sum_buy_volume[4] }}</div>
      <div>{{ $store.state.sum_sell_volume[4] }}</div>
      <div>{{ $store.state.rate_buy_volume[4] }}</div>
      <div>{{ $store.state.rate_sell_volume[4] }}</div>
      <div>{{ $store.state.sum_buy_volume[5] }}</div>
      <div>{{ $store.state.sum_sell_volume[5] }}</div>
      <div>{{ $store.state.rate_buy_volume[5] }}</div>
      <div>{{ $store.state.rate_sell_volume[5] }}</div>
    </div>

    <v-btn @click="bybittest()">bybittest</v-btn>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  methods: {
    bybittest() {
      this.$store.dispatch("bybittest");
    },
  },
});
</script>
