<template>
  <v-app>
    <router-view />
    <div
      class="absolute top-32 left-10 right-10 text-center text-red-500"
      v-if="loading === true"
    >
      로딩중...
    </div>
    <div v-if="loading === false">
      <div class="grid grid-cols-1 grid-flow-row" style="height: 100%">
        <TradingScreen />
      </div>
    </div>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import TradingScreen from "./views/TradingScreen/TradingScreen.vue";

export default defineComponent({
  name: "App",

  components: {
    TradingScreen,
  },
  data: () => ({
    loading: true,
  }),
  created() {
    this.$store.dispatch("timerStart");
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
});
</script>
